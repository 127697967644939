import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../Header.css";

function Header() {
  const [menuItems, setMenuItems] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const menuTexts = ["Home", "Works"];
    const newMenuItems = menuTexts.map((text) => {
      const letters = text.split("");
      return {
        text: text,
        letters: letters,
      };
    });

    setMenuItems(newMenuItems);
  }, []);

  const nameLetters = "ZAYD".split("");

  return (
    <header className="max-w-screen-2xl mx-auto p-6 flex justify-between items-start ">
      <Link to="/" className="menu__item">
        <h2 className="menu__item">
          <div className="menu__letters">
            {nameLetters.map((letter, idx) => (
              <span className="h2" key={idx} style={{ "--index": idx }}>
                {letter}
              </span>
            ))}
          </div>
          <div className="menu__letters clone">
            {nameLetters.map((letter, idx) => (
              <span className="h2" key={idx} style={{ "--index": idx }}>
                {letter}
              </span>
            ))}
          </div>
        </h2>
      </Link>
      <nav className="flex flex-row items-center justify-between w-full  max-w-[200px] sm:max-w-[300px]">
        <ul className="flex flex-col space-y-1">
          {menuItems.map((item, index) => {
            const isActive =
              location.pathname ===
              (item.text === "Home" ? "/" : `/${item.text.toLowerCase()}`);
            return (
              <li
                key={index}
                className="menu__item"
                style={{ listStyleType: isActive ? "square" : "none" }}
              >
                <Link
                  to={
                    item.text === "Home" ? "/" : `/${item.text.toLowerCase()}`
                  }
                  className="menu__item-text"
                  style={{
                    opacity: isActive ? 1 : 0.5,
                  }}
                >
                  <div className="menu__letters">
                    {item.letters.map((letter, idx) => (
                      <span key={idx} style={{ "--index": idx }}>
                        {letter}
                      </span>
                    ))}
                  </div>
                  <div className="menu__letters clone">
                    {item.letters.map((letter, idx) => (
                      <span key={idx} style={{ "--index": idx }}>
                        {letter}
                      </span>
                    ))}
                  </div>
                </Link>
              </li>
            );
          })}
        </ul>
        <a
          href="mailto:zayd.maadal@hotmail.com"
          className="contact-button contact"
        >
          <span className="contact-left"></span>
          Contact
          <span className="contact-right"></span>
        </a>
      </nav>
    </header>
  );
}

export default Header;
