import React, { useState } from "react";

function ProjectItem({ work }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className="flex-col w-full border-b-2"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <div className="flex justify-between py-4 text-sm">
        <div className="font-bold uppercase">{work.name}</div>
        <div>{work.subject}</div>
        <div>{work.tags.join("/")}</div>
        <div>{work.year}</div>
      </div>
      <div
        className={`flex justify-between gap-8 w-full mb-8 text-sm ${
          isOpen ? "h-[200px]" : "h-[0px]"
        } overflow-hidden`}
        style={{ transition: "height 0.5s ease" }}
      >
        <div className="bottom-0 left-0 w-2/3 ">
          <p>{work.description}</p>
        </div>
        {work.imagePaths.slice(0, 2).map((image, index) => (
          <img
            key={index}
            src={image}
            alt={work.name}
            className={
              index === 0 ? "h-40 w-60 object-cover" : "h-60 w-80 object-cover"
            }
          />
        ))}
      </div>
    </div>
  );
}

export default ProjectItem;
