import React, { useState, useEffect, useCallback } from "react";
import SplitTitle from "./SplitTitle";

const importAll = (r) => {
  return r.keys().map(r);
};

const images = importAll(
  require.context("/public/Images/loop", false, /\.(png|jpe?g|svg|gif|webp)$/)
);

function Gallery() {
  const [bgImage, setBgImage] = useState(images[0]);

  const handleScroll = useCallback(() => {
    const scrollPosition = window.scrollY;
    const imageIndex = Math.floor(scrollPosition / 220) % images.length;
    setBgImage(images[imageIndex]);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <>
      <SplitTitle firstName="Zayd" lastName="Maadal" />
      <div className="mb-5 gallery">
        <div className="relative w-full h-[350px] md:h-[600px]">
          <div className="absolute inset-0 bg-black opacity-10 z-10"></div>
          <div
            className="w-full h-full bg-cover bg-center md:bg-fixed"
            style={{ backgroundImage: `url("/Images/vinland.webp")` }}
          ></div>
          <div
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[150px] h-[200px] md:w-[235px] md:h-[335px] bg-cover bg-center z-20"
            style={{ backgroundImage: `url(${bgImage})` }}
          />
        </div>
      </div>
    </>
  );
}

export default Gallery;
