import React from "react";
import workData from "./data/workData.json";
import { useParams, Link } from "react-router-dom";

function Detail() {
  const { name } = useParams();

  const work = workData.find((work) => work.name === name);

  return work ? (
    <>
      <img
        src={work.imagePaths[0]}
        alt={work.name}
        className="w-full max-h-[600px] lg:max-h-[800px] object-cover"
      />
      <div className="max-w-screen-2xl mx-auto p-4">
        <div className="flex justify-between lg:items-center lg:gap-20 gap-8 flex-col lg:flex-row">
          <h1>{work.name}</h1>
          <div className="flex flex-col items-start">
            <p className="text-xs">{work.description}</p>
            {work.website && (
              <a href={work.website} target="_blank" rel="noopener noreferrer">
                <button className="mt-4 lg:mt-2 px-4 py-2 bg-primary text-white">
                  View Project
                </button>
              </a>
            )}
          </div>
        </div>

        <div className="flex justify-between lg:items-center flex-row mt-10 text-xs gap-8 lg:py-20">
          <p>{work.subject}</p>
          <p>{work.skills.join(", ")}</p>
          <p>{work.tags.join(", ")}</p>
          <p>{work.year}</p>
        </div>
        <div className="flex flex-col lg:flex-row mt-10 justify-between flex-wrap gap-4 test">
          {work.imagePaths.slice(1, 3).map((image, index) => (
            <img
              key={index}
              src={image}
              alt={work.name}
              className={`lg:w-[47%] w-full h-auto ${
                index % 2 !== 0 ? "lg:mt-60" : ""
              } object-cover h-auto xl:h-[600px] lg:h-[500px]`}
            />
          ))}
          {work.description2 && (
            <p className="my-10 lg:my-20 text-xs w-full">
              {parseDescription(work.description2)}
            </p>
          )}
          {work.imagePaths.slice(3).map((image, index) => (
            <img
              key={index}
              src={image}
              alt={work.name}
              className={`lg:w-[47%] w-full h-auto ${
                index % 2 !== 0 ? "lg:mt-60" : ""
              } object-cover h-auto lg:h-[600px]`}
            />
          ))}
        </div>
      </div>
    </>
  ) : (
    <div>
      <p>Something went wrong. The work you're looking for cannot be found.</p>
      <Link to="/">Go back home</Link>
    </div>
  );
}
function parseDescription(description) {
  return (
    <p>
      {description.map((part, index) =>
        typeof part === "string" ? (
          part
        ) : (
          <a
            key={index}
            href={part.url}
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            {part.text}
          </a>
        )
      )}
    </p>
  );
}

export default Detail;
