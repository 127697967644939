import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

function SplitTitle({ firstName, lastName }) {
  const lastNameRef = useRef(null);
  const containerRef = useRef(null);

  const setupAnimation = () => {
    const lastName = lastNameRef.current;
    const container = containerRef.current;

    if (!lastName || !container) return;

    lastName.getBoundingClientRect();

    const lastNameWidth = lastName.offsetWidth;
    const containerWidth = container.offsetWidth || window.innerWidth;

    const maxTranslateX = Math.min(
      containerWidth - lastNameWidth - 20,
      containerWidth - lastNameWidth * 1.68
    );

    ScrollTrigger.getAll().forEach((trigger) => trigger.kill());

    requestAnimationFrame(() => {
      gsap.to(lastName, {
        x: maxTranslateX,
        ease: "none",
        scrollTrigger: {
          trigger: container,
          start: "top 90%",
          end: () => `+=${window.innerHeight / 1.1}`,
          scrub: true,
          invalidateOnRefresh: true,
        },
      });
    });
  };

  const initializeAnimation = () => {
    document.fonts.ready.then(() => {
      setupAnimation();
    });
  };

  useEffect(() => {
    window.addEventListener("load", initializeAnimation); // Ensure full page load
    window.addEventListener("resize", initializeAnimation);

    return () => {
      window.removeEventListener("load", initializeAnimation);
      window.removeEventListener("resize", initializeAnimation);
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  return (
    <div ref={containerRef} className="split-title-container mt-10">
      <h1 style={{ display: "flex", alignItems: "center" }}>
        <span>{firstName}</span>
        <span ref={lastNameRef} style={{ marginLeft: "10px" }}>
          {lastName}
        </span>
      </h1>
    </div>
  );
}

export default SplitTitle;
