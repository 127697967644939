import { useState } from "react";
import { Link } from "react-router-dom";
import workData from "./data/workData.json";
import ProjectItem from "./components/project/ProjectItem";

function Works() {
  const [filter, setFilter] = useState([]);
  const tags = Array.from(new Set(workData.flatMap((work) => work.tags)));

  const filteredWorkData =
    filter.length === 0
      ? workData
      : workData.filter((work) =>
          work.tags.some((tag) => filter.includes(tag))
        );

  const toggleFilter = (tag) => {
    if (tag === "all") {
      setFilter([]);
    } else if (filter.includes(tag)) {
      setFilter(filter.filter((t) => t !== tag));
    } else {
      setFilter([...filter, tag]);
    }
  };

  function FilterButton({ tag, toggleFilter, active }) {
    return (
      <button
        onClick={() => toggleFilter(tag)}
        className="contact-button"
        style={{ fontWeight: "300" }}
      >
        <span className={`contact-left ${active ? "" : "hidden"}`}></span>
        {tag}
        <span className={`contact-right ${active ? "" : "hidden"}`}></span>
      </button>
    );
  }

  return (
    <div className="max-w-screen-2xl mx-auto p-4">
      <h1>My Work</h1>

      <div className="w-full py-4 border-b border-black mb-8 lg:mb-4 flex justify-between">
        <div className="flex gap-4">
          <FilterButton
            tag="all"
            toggleFilter={toggleFilter}
            active={filter.length === 0}
          />
          {tags.map((tag) => (
            <FilterButton
              key={tag}
              tag={tag}
              toggleFilter={toggleFilter}
              active={filter.includes(tag)}
            />
          ))}
        </div>
        <p className="italic opacity-50 hidden md:block">
          Hover on a project to learn more.
        </p>
      </div>
      <div className="block md:hidden flex flex-wrap w-full justify-between gap-y-8">
        {filteredWorkData.map((work, index) => (
          <Link to={`/detail/${work.name}`} className="w-full sm:w-1/2 px-2">
            <div
              key={index}
              className="flex flex-col items-start cursor-pointer uppercase group"
            >
              <div className="w-full flex flex-col items-start">
                <div className="relative h-60 sm:h-40 w-full overflow-hidden mb-2">
                  <div
                    className="absolute inset-0 bg-cover bg-center transition-transform duration-300 ease-in-out transform group-hover:scale-110"
                    style={{ backgroundImage: `url(${work.imagePaths[0]})` }}
                    aria-hidden="true"
                  />
                </div>
                <h3 className="text-sm font-medium text-left">{work.name}</h3>
                <p className="text-xs text-gray-500 text-left">
                  {work.tags.join(", ")}
                </p>
              </div>
            </div>
          </Link>
        ))}
      </div>
      <div className="hidden md:block">
        {filteredWorkData.map((work, index) => (
          <Link to={`/detail/${work.name}`}>
            <ProjectItem key={index} work={work} />
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Works;
