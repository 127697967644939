import React, { useRef, useEffect, useState } from "react";
import { useGLTF, Html } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";

export function Model(props) {
  const [hovered, setHovered] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const meshRef = useRef();
  const { nodes } = useGLTF("/models/kunai.glb");

  const scrollFactor = 0.01;
  const initialRotation = new THREE.Vector3(0, 0, 0);
  const initialPosition = new THREE.Vector3(0, 0, 0);

  // Resize & scroll listeners
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    const handleScroll = () => setScrollY(window.scrollY);

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Update object transformation per frame
  useFrame(() => {
    if (meshRef.current) {
      const rotationZ = scrollY * scrollFactor;
      meshRef.current.rotation.z = rotationZ;

      // Smoothly reset to initial position/rotation
      meshRef.current.rotation.x = THREE.MathUtils.lerp(
        meshRef.current.rotation.x,
        initialRotation.x,
        0.1
      );
      meshRef.current.rotation.y = THREE.MathUtils.lerp(
        meshRef.current.rotation.y,
        initialRotation.y,
        0.1
      );
      meshRef.current.rotation.z = THREE.MathUtils.lerp(
        meshRef.current.rotation.z,
        rotationZ,
        0.1
      );

      meshRef.current.position.x = THREE.MathUtils.lerp(
        meshRef.current.position.x,
        initialPosition.x,
        0.1
      );
      meshRef.current.position.y = THREE.MathUtils.lerp(
        meshRef.current.position.y,
        initialPosition.y,
        0.1
      );
      meshRef.current.position.z = THREE.MathUtils.lerp(
        meshRef.current.position.z,
        initialPosition.z,
        0.1
      );
    }
  });

  return (
    <group {...props} dispose={null} ref={meshRef}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_185.geometry}
        scale={0.16}
        onPointerOver={() => setHovered(true)}
        onPointerOut={() => setHovered(false)}
      >
        <Html center>
          <div
            style={{
              color: "white",
              backgroundColor: "#3FC473",
              padding: "0.5em",
              borderRadius: "30px",
              opacity: hovered ? 1 : 0,
              transition: "opacity 0.2s ease",
              width: "max-content",
              fontSize: "0.75rem",
              fontWeight: "bold",
            }}
          >
            ✦ SHURIKEN ✦
          </div>
        </Html>
        <meshStandardMaterial
          color="#3fc473"
          metalness={0.7}
          roughness={isMobile ? 0.3 : 0.2}
        />
      </mesh>
    </group>
  );
}

useGLTF.preload("/models/kunai.glb");
