import React from "react";
import "../Talen.css";

function Talen() {
  const languages = [
    { name: "HTML", src: "/Images/html.webp" },
    { name: "CSS", src: "/Images/css.webp" },
    { name: "JavaScript", src: "/Images/javascript.webp" },
    { name: "React", src: "/Images/react.webp" },
    { name: "Figma", src: "/Images/figma.webp" },
    { name: "PHP", src: "/Images/php.webp" },
    { name: "MySQL", src: "/Images/mysql.webp" },
    { name: "Node.js", src: "/Images/node.webp" },
  ];

  return (
    <div className="flex flex-wrap justify-center md:justify-around gap-12 mt-20 talen">
      {languages.map((lang) => (
        <div
          key={lang.name}
          className="flex items-center justify-center w-1/4 sm:w-1/4 p-2 img-container"
        >
          <img
            src={lang.src}
            alt={lang.name}
            className="w-14 h-14 md:w-20 md:h-20 lg:w-24 lg:h-24 object-contain"
          />
        </div>
      ))}
    </div>
  );
}

export default Talen;
