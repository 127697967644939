import { Canvas } from "@react-three/fiber";
import { Model } from "./Model";
import { PresentationControls, Environment } from "@react-three/drei";
import { EffectComposer, Bloom } from "@react-three/postprocessing";
import { useState, useEffect, useMemo } from "react";

export default function ThreeD() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Detect mobiel apparaat
  const defaultConfig = useMemo(() => ({ mass: 2, tension: 300 }), []);
  const mobileConfig = useMemo(() => ({ mass: 2, tension: 900 }), []);
  const [config, setConfig] = useState(isMobile ? mobileConfig : defaultConfig);

  // Schermgrootte aanpassen
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setConfig(isMobile ? mobileConfig : defaultConfig);
  }, [isMobile, defaultConfig, mobileConfig]);

  return (
    <Canvas style={{ width: "100%", height: isMobile ? "50vh" : "40vh" }}>
      <Environment preset="forest" />
      <PresentationControls
        global
        config={config}
        snap={{ mass: 4, tension: 900 }}
        rotation={[0, 0, 0]}
        polar={[-Math.PI / 1.6, Math.PI / 1.6]}
        azimuth={[-Math.PI / 1.4, Math.PI / 2]}
      >
        <Model />
        <EffectComposer>
          <Bloom
            luminanceThreshold={0.9} // Lagere drempel voor visueel effect
            intensity={isMobile ? 5 : 6.85} // Minder intens op mobiel
            levels={6}
            mipmapBlur
          />
        </EffectComposer>
      </PresentationControls>
    </Canvas>
  );
}
