import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./index.css";
import Home from "./Home";
import Works from "./Works";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Detail from "./Detail";
import SmoothScrolling from "./utils/SmoothScrolling";

const LoadingScreen = () => {
  return (
    <div className="fixed inset-0 flex justify-center items-center bg-white z-50">
      <h1 className="text-2xl  font-bold">
        Loading<span className="dots"></span>
      </h1>
    </div>
  );
};

function App() {
  const [fontsLoaded, setFontsLoaded] = useState(false);

  useEffect(() => {
    document.fonts.ready.then(() => {
      setFontsLoaded(true);
    });
  }, []);

  return (
    <SmoothScrolling>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Works" element={<Works />} />
          <Route path="/Detail/:name" element={<Detail />} />
        </Routes>
        <Footer />
        {!fontsLoaded && <LoadingScreen />}
      </Router>
    </SmoothScrolling>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
